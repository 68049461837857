import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { CodeBlock } from "../commonComponents"


export default function IrisGPTAPIDocs() {
  const agent_handoff_json = {
      "htmltext": "Thanks for your patience. I will connect you to an agent now. For your reference, the phone number to contact support is 1-833-835-4662.",
      "links": [],
      "linksTitle": "",
      "mdText": "Thanks for your patience. I will connect you to an agent now. For your reference, the phone number to contact support is 1-833-835-4662.",
      "plaintext": "Thanks for your patience. I will connect you to an agent now. For your reference, the phone number to contact support is 1-833-835-4662.",
      "source": "intent-tags",
      "urls": null
  }

  const no_answer_json = {
      "htmltext": "<p>I’m sorry, but I didn’t find relevant information in the help center. Can you please rephrase your question?</p>",
      "links": [],
      "linksTitle": "",
      "mdText": "I’m sorry, but I didn’t find relevant information in the help center. Can you please rephrase your question?",
      "plaintext": "I’m sorry, but I didn’t find relevant information in the help center. Can you please rephrase your question?",
      "source": "python-ml",
      "urls": []
  }

  const json = {
  "result":
    {
   "htmltext":"Agents can leverage knowledge to help customers by using the Knowledge Capture app. This app allows agents to search the Help Center without leaving the ticket, insert links to relevant Help Center articles in ticket comments, add inline feedback to existing articles, and create new articles while answering tickets using a pre-defined template. This helps agents assist customers effectively while also enhancing self-service options for other customers.\n\u003cbr\u003e\nCheck out this article for more information:\n\u003cul\u003e \u003cli style=\"list-style-position: inside;\"\u003e\u003ca href='https://d3v-irisagent.zendesk.com/hc/en-us/articles/360046592594-How-can-agents-leverage-knowledge-to-help-customers' target='_blank'\u003eHow can agents leverage knowledge to help customers?\u003c/a\u003e\u003c/li\u003e \u003c/ul\u003e",
   "links":[
      {
         "text":"How can agents leverage knowledge to help customers?",
         "url":"https://d3v-irisagent.zendesk.com/hc/en-us/articles/360046592594-How-can-agents-leverage-knowledge-to-help-customers"
      }
   ],
   "linksTitle":"Check out this article for more information:",
   "plaintext":"Agents can leverage knowledge to help customers by using the Knowledge Capture app. This app allows agents to search the Help Center without leaving the ticket, insert links to relevant Help Center articles in ticket comments, add inline feedback to existing articles, and create new articles while answering tickets using a pre-defined template. This helps agents assist customers effectively while also enhancing self-service options for other customers.",
   "mdText":"Agents can leverage knowledge to help customers by using the Knowledge Capture app. This app allows agents to search the Help Center without leaving the ticket, insert links to relevant Help Center articles in ticket comments, add inline feedback to existing articles, and create new articles while answering tickets using a pre-defined template. This helps agents assist customers effectively while also enhancing self-service options for other customers. - Check if the control number is mandatory for the account you are trying to use. If it is, make sure the control number is correctly entered.\n- Verify that you have the necessary permissions to edit or create journal entries, as permissions can affect your ability to make changes.\n- If the journal entry is already posted, you may need to reverse it and create a new entry with the correct control number.\n## Check out the following resource(s) for more information:\n- [Need to change control while Journal Entry Posting.](https://example.com/670da173611ddba46ef12ed1)\n- [ACCOUNTING SETTINGS: Journals](https://example.com/670da22d611ddba46ef13675)",
   "source":"python-ml",
   "urls":[
      "https://d3v-irisagent.zendesk.com/hc/en-us/articles/360046592594-How-can-agents-leverage-knowledge-to-help-customers"
   ]
}
}

  return (
    <Layout
      keywords="IrisGPT API guide"
      title="Use IrisGPT's API to power intelligent AI answers in your products"
      description="Documentation on how to use IrisGPT's API to power intelligent, Gen-AI answers in your products"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/irisgpt-api-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>IrisGPT API Guide</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>IrisGPT API</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>

                    <h1>IrisGPT API Documentation</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    IrisAgent's <a href="https://irisagent.com/support-agent-assist/">IrisGPT AI answers</a> can be used an API to power intelligent conversations on any of your frontend webpages. It can search through your internal and external knowledge articles, community forums, product documentation, and other informational content.
                    <br/>
                    <br/>
                    <h2>
                    API Guide
                    </h2>
                    <br/>
                    Below is a <span style={{"font-family": "Consolas"}}>curl</span> command to call our IrisGPT API:
                    <CodeBlock language="javascript" value={`curl -X POST --location "https://api1.irisagent.com/v1/irisgpt/ask" \\
    -H "Content-Type: application/json" \\
    -H "Authorization: Bearer Replace_me_business_id" \\
    -H "X-Language-Iso-Code: en" \\
    -d '{
          "conversationId": "12345"
          "query": "how do agents use knowledge"
        }'`}>
</CodeBlock>
<br/>
The API inputs are as follows,
<br/><br/>
<table style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
    <tr>
        <th>Field</th>
        <th>Description</th>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>conversationId</span></td>
        <td>[Required] Unique ticket id if the input is a ticket or message id if the input is a chat message/comment</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>query</span></td>
        <td>[Required] URL-encoded query from the user</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>Replace_me_business_id</span></td>
        <td>This is the ID provided by the IrisAgent team</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>Chat-Identifier</span></td>
        <td>[Optional] The Chat-Identifier is an optional header in the format of uuid that is used to track the conversation session. If not provided, a new chat session will automatically be created. While not required for single request/response interactions, it is necessary for maintaining continuity in ongoing conversations.</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>productId</span></td>
        <td>[Optional] If you have multiple products and knowledge base segmented by products, you can provide the product ID here</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>X-Language-Iso-Code</span></td>
        <td>[Optional] This optional header allows you to specify the desired language for IrisGPT's responses. If not provided, automatic language detection will be used</td>
    </tr>
</table>
<br/><br/>
A sample output of the API when an AI answer is found:
<br/><br/>
<CodeBlock language="json" value={JSON.stringify(json, null, 2)}>
</CodeBlock>


<br/><br/>
A sample output of the API when no answer was found. Note that <span style={{"font-family": "Consolas"}}>links</span> will be an empty array in this situation and <span style={{"font-family": "Consolas"}}>source</span> value will be <span style={{"font-family": "Consolas"}}>python-ml</span>.
<br/><br/>
<CodeBlock language="json" value={JSON.stringify(no_answer_json, null, 2)}>
</CodeBlock>

<br/><br/>
A sample output of the API when the intent to connect with an agent is detected. Note that <span style={{"font-family": "Consolas"}}>source</span> value will be <span style={{"font-family": "Consolas"}}>intent-tags</span> and the <span style={{"font-family": "Consolas"}}>htmltext</span> will contain the phrase <span style={{"font-family": "Consolas"}}>I will connect you to an agent now</span>.
<br/><br/>
<CodeBlock language="json" value={JSON.stringify(agent_handoff_json, null, 2)}>
</CodeBlock>

                    </div>
                    </div>
                    </section>

</Layout>
  )
}